import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Sentry from "@sentry/react";
import { useNavigate } from "react-router";
import { v4 as uuidv4 } from "uuid";
import HomeUI from "./Home";
import useStyles from "./Home.styles";
import {
  clearSendNipValidatePhone,
  clearValidatePhoneNip,
  getUserData,
  getUserDataDetail,
  postReverseOtp,
  sendNipValidatePhone,
  validatePhoneNip,
  validateReverseOtp,
} from "../../redux/actions/origination.action";
import { useQuery } from "../../hooks";
import { getUuidFromPhoneNumber } from "../../constants/util";
import { postLeadAssigment } from "../../redux/actions/operations.action";
import I18n from "../../i18n";

export function Home() {
  const history = useNavigate();
  const dispatch = useDispatch();
  const query = useQuery();

  const {
    userData,
    newUser,
    codeConfirmed,
    sendNipPhone,
    validateNipPhone,
    redirectionUrlObject,
  } = useSelector((state) => state.originationReducer);

  const classes = useStyles();

  const [phone, setPhone] = useState("");
  const [lada, setLada] = useState(
    process.env.REACT_APP_MAP_COUNTRY === "mx" ? "+52" : "+1"
  );
  const [errorPhone, setErrorPhone] = useState("");
  const [code, setCode] = useState("");
  const [isValidate, setIsValidate] = useState(false);
  const [isCodeValidate, setIsCodeValidate] = useState(false);
  const [hasRequestProcess, setHasRequestProcess] = useState(false);
  const [isStepToValidate, setIsStepToValidate] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [otpVendor, setOtpVendor] = useState("veriphone");
  const i18n = I18n(process.env.REACT_APP_MAP_COUNTRY);

  useEffect(() => {
    const version = query.get("version");
    if (version) {
      sessionStorage.setItem("version", version);
    }

    const utmSource = query.get("utm_source");
    const utmMedium = query.get("utm_medium");
    const utmCampaign = query.get("utm_campaign");
    sessionStorage.setItem("utm_source", utmSource);
    sessionStorage.setItem("utm_medium", utmMedium);
    sessionStorage.setItem("utm_campaign", utmCampaign);
    const _otpVendor = query.get("otp-vendor");
    if (_otpVendor) {
      setOtpVendor(_otpVendor);
    }
    const sessionUuid = query.get("sessionUuid");
    if (sessionUuid) {
      // consume verification
      dispatch(validateReverseOtp(sessionUuid)).then(() => {});
      return;
    }

    if (!phone) {
      return;
    }

    if (newUser) {
      window.analytics.identify({
        $phone: `${lada + phone}`,
        "utm source": sessionStorage.getItem("utm_source"),
        "utm medium": sessionStorage.getItem("utm_medium"),
        "utm campaign": sessionStorage.getItem("utm_campaign"),
      });
      window.analytics.track("Inicio de registro", {
        $phone: `${lada + phone}`,
        "utm source": sessionStorage.getItem("utm_source"),
        "utm medium": sessionStorage.getItem("utm_medium"),
        "utm campaign": sessionStorage.getItem("utm_campaign"),
      });
      history(`/${lada + phone}/about-you`, { replace: true });
    }

    return () => {
      dispatch(clearSendNipValidatePhone());
      dispatch(clearValidatePhoneNip());
    };
  }, []);

  useEffect(() => {
    if (!phone) {
      return;
    }
    if (newUser) {
      window.analytics.identify({
        $phone: `${lada + phone}`,
        "utm source": sessionStorage.getItem("utm_source"),
        "utm medium": sessionStorage.getItem("utm_medium"),
        "utm campaign": sessionStorage.getItem("utm_campaign"),
      });
      window.analytics.track("Inicio de registro", {
        $phone: `${lada + phone}`,
        "utm source": sessionStorage.getItem("utm_source"),
        "utm medium": sessionStorage.getItem("utm_medium"),
        "utm campaign": sessionStorage.getItem("utm_campaign"),
      });
      history(`/${lada + phone}/about-you`, { replace: true });
    }
  }, [newUser]);

  useEffect(() => {
    if (codeConfirmed) {
      dispatch(
        getUserDataDetail(userData.data.user_id, userData.data.user_type)
      );
    }
  }, [codeConfirmed]);

  useEffect(() => {
    if (Object.keys(sendNipPhone).length !== 0) {
      setIsStepToValidate(true);
    }
  }, [sendNipPhone]);

  useEffect(() => {
    const anonymousId = JSON.parse(sessionStorage.getItem("anonymous_id"));
    if (Object.keys(validateNipPhone).length !== 0) {
      sessionStorage.setItem(
        "clientToken",
        `${validateNipPhone.data.access_type} ${validateNipPhone.data.access_token}`
      );
      if (validateNipPhone.data.phone_number) {
        setPhone(validateNipPhone.data.phone_number.slice(-10));
      }
      dispatch(getUserData(lada + phone, anonymousId || ""));
    }
  }, [validateNipPhone]);

  useEffect(() => {
    const { redirection_url } = redirectionUrlObject;
    if (redirection_url) {
      window.location.href = redirection_url;
    }
  }, [redirectionUrlObject]);

  const validatePhoneNumber = (phoneNumberHandle) => {
    const re = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
    return re.test(phoneNumberHandle);
  };

  const validateCodeNumber = (codeNumberHandle) => {
    const re = /^[0-9]{6}$/im;
    return re.test(codeNumberHandle);
  };

  const handleChangePhone = (event) => {
    const phoneNumberHandle = event.target.value;
    if (phoneNumberHandle.length <= 10) {
      if (phoneNumberHandle !== "") {
        if (!validatePhoneNumber(phoneNumberHandle)) {
          setIsValidate(false);
        } else {
          setIsValidate(true);
        }
      } else {
        setIsValidate(false);
      }
      setPhone(event.target.value);
    }
  };

  const generateTimestamp = () => new Date().toISOString();

  const assignNewLead = () => {
    const uniqueId = uuidv4();
    const body = {
      lead_id: uniqueId.toString(),
      lead_type: "INTERNAL",
      shopkeeper_phone: lada + phone,
      utm_source: sessionStorage.getItem("utm_source"),
      utm_medium: sessionStorage.getItem("utm_medium"),
      utm_campaign: sessionStorage.getItem("utm_campaign"),
      created_at: generateTimestamp(),
      updated_at: generateTimestamp(),
    };
    dispatch(postLeadAssigment(body));
  };

  const onContinueAction = () => {
    let anonymousId = JSON.parse(sessionStorage.getItem("anonymous_id"));
    if (!anonymousId) {
      anonymousId = getUuidFromPhoneNumber(lada, phone);
      sessionStorage.setItem("anonymous_id", JSON.stringify(anonymousId));
    }
    sessionStorage.setItem("phone_number", lada + phone);
    Sentry.setUser({ username: lada + phone });
    Sentry.setExtra("anonymous_id", anonymousId);
    if (otpVendor === "veriphone") {
      dispatch(postReverseOtp(lada + phone, anonymousId || "")).then(() => {
        dispatch(getUserData(lada + phone, anonymousId || ""));
        assignNewLead();
      });

      return;
    }
    dispatch(
      sendNipValidatePhone({
        anonymous_id: anonymousId || "",
        phone_number: lada + phone,
      })
    );
    assignNewLead();
  };

  const handleChangeCode = (value) => {
    const codeNumberHandle = value;
    if (codeNumberHandle !== "") {
      if (!validateCodeNumber(codeNumberHandle)) {
        setIsCodeValidate(false);
      } else {
        setIsCodeValidate(true);
      }
    } else {
      setIsCodeValidate(false);
    }
    setCode(value);
  };

  const handleChangeInputLada = () => (event) => {
    setLada(event.target.value);
  };

  const redirectAction = () => window.open("https://bit.ly/3CvvwyM");

  const _onContinueValidateNip = () => {
    const anonymousId = JSON.parse(sessionStorage.getItem("anonymous_id"));
    if (!anonymousId) {
      anonymousId = getUuidFromPhoneNumber(lada, phone);
      sessionStorage.setItem("anonymous_id", JSON.stringify(anonymousId));
    }
    dispatch(
      validatePhoneNip({
        code,
        phone_number: lada + phone,
        anonymous_id: anonymousId || "",
      })
    );
    window.analytics.track("Ingresó código de validación");
  };

  const resendNip = () => {
    const anonymousId = JSON.parse(sessionStorage.getItem("anonymous_id"));
    sessionStorage.setItem("phone_number", lada + phone);
    Sentry.setUser({ username: lada + phone });
    Sentry.setExtra("anonymous_id", anonymousId);
    dispatch(
      sendNipValidatePhone({
        anonymous_id: anonymousId || "",
        phone_number: lada + phone,
      })
    );
    setIsDisabled(true);
    setTimer(60);
  };

  const [timer, setTimer] = useState(0);
  const [minutes, setMinutes] = useState("01");
  const [seconds, setSeconds] = useState("00");

  useEffect(() => {
    setTimeout(() => {
      if (timer === 0) {
        setIsDisabled(false);
      } else {
        converToMinutes();
        convertToSeconds();
        setTimer((prevState) => prevState - 1);
      }
    }, 1000);
  }, [timer]);

  const converToMinutes = () => {
    let minute = Math.floor((timer / 60) % 60);
    minute = minute < 10 ? `0${minute}` : minute;
    setMinutes(minute);
  };

  const convertToSeconds = () => {
    let second = timer % 60;
    second = second < 10 ? `0${second}` : second;
    setSeconds(second);
  };

  const backHome = () => {
    dispatch(clearSendNipValidatePhone());
    dispatch(clearValidatePhoneNip());
    setIsStepToValidate(false);
    setPhone("");
  };

  return (
    <HomeUI
      classes={classes}
      _handleChangePhone={handleChangePhone}
      _onContinueAction={onContinueAction}
      errorPhone={errorPhone}
      isValidate={isValidate}
      hasRequestProcess={hasRequestProcess}
      _handleChangeCode={handleChangeCode}
      errorCode=""
      phoneNumber={phone}
      isValidateCode={isCodeValidate}
      _redirectAction={redirectAction}
      lada={lada}
      handleChangeInputLada={handleChangeInputLada}
      isStepToValidate={isStepToValidate}
      _onContinueValidateNip={_onContinueValidateNip}
      resendNip={resendNip}
      isDisabled={isDisabled}
      minutes={minutes}
      seconds={seconds}
      backHome={backHome}
      i18n={i18n}
    />
  );
}

Home.propTypes = {};

export default Home;
