/* eslint-disable indent */
import * as ACTION from "../actionTypes/origination";
import * as originationService from "../../services/origination.service";

export const getUserData = (phoneNumber, anonymousId) => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_GET_USER_DATA,
  });

  try {
    const userData = await originationService.getUserData(
      phoneNumber,
      anonymousId
    );
    if (userData) {
      dispatch({
        type: ACTION.RESPONSE_GET_USER_DATA,
        result: userData,
      });
    }
  } catch (error) {
    if (error.response?.status === 404) {
      dispatch({
        type: ACTION.NEW_USER,
      });
      return;
    }
    dispatch({
      type: ACTION.ERROR_GET_USER_DATA,
      error,
    });
  }
};

export const clearUserData = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_GET_USER_DATA,
  });
};

export const getUserDataDetail = (userId, userType) => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_GET_USER_DATA_DETAIL,
  });

  try {
    const userData = await originationService.getUserDataDetail(
      userId,
      userType
    );
    if (userData) {
      dispatch({
        type: ACTION.RESPONSE_GET_USER_DATA_DETAIL,
        result: userData,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_GET_USER_DATA_DETAIL,
      error,
    });
  }
};

export const clearUserDataDetail = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_GET_USER_DATA_DETAIL,
  });
};

export const createRequestOrigination = (body) => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_POST_USER,
  });

  try {
    const userData = await originationService.createRequestOrigination(body);
    if (userData) {
      dispatch({
        type: ACTION.RESPONSE_POST_USER,
        result: userData,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_POST_USER,
      error,
    });
  }
};

export const clearRequestOrigination = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_POST_USER,
  });
};

export const sendCode = (user_type_id, user_id) => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_SEND_CODE,
  });

  try {
    const userData = await originationService.sendCode(user_type_id, user_id);
    if (userData) {
      dispatch({
        type: ACTION.RESPONSE_CODE_DATA,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_CODE_DATA,
      error,
    });
  }
};

export const confirmCode =
  (user_type_id, user_id, code) => async (dispatch) => {
    dispatch({
      type: ACTION.INIT_CODE_CONFIRMATION,
    });

    try {
      const userData = await originationService.confirmCode(
        user_type_id,
        user_id,
        code
      );
      if (userData) {
        dispatch({
          type: ACTION.RESPONSE_CODE_CONFIRMATION,
          result: userData,
        });
      }
    } catch (error) {
      dispatch({
        type: ACTION.ERROR_CODE_CONFIRMATION,
        error,
      });
    }
  };

export const sendNIP = (user_type_id, user_id) => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_SEND_NIP,
  });

  try {
    const nipData = await originationService.sendNIP(user_type_id, user_id);
    if (nipData) {
      dispatch({
        type: ACTION.RESPONSE_NIP_DATA,
        result: nipData.data,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_NIP_DATA,
      error,
    });
  }
};

export const clearSendNipData = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_SEND_NIP_DATA,
  });
};

export const resendNIP = (user_type_id, user_id, body) => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_RESEND_NIP,
  });

  try {
    const nipData = await originationService.sendNIP(
      user_type_id,
      user_id,
      body
    );
    if (nipData) {
      dispatch({
        type: ACTION.RESPONSE_RESEND_NIP_DATA,
        result: nipData.data,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_RESEND_NIP_DATA,
      error,
    });
  }
};

export const clearResendNipData = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_RESEND_NIP_DATA,
  });
};

export const confirmNIP = (user_type_id, user_id, nip) => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_NIP_CONFIRMATION,
  });

  try {
    const nipData = await originationService.confirmNIP(
      user_type_id,
      user_id,
      nip
    );
    if (nipData) {
      dispatch({
        type: ACTION.RESPONSE_NIP_CONFIRMATION,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_NIP_CONFIRMATION,
      error,
    });
  }
};

export const createPwd =
  (user_type_id, user_id, password) => async (dispatch) => {
    dispatch({
      type: ACTION.INIT_PWD_CREATION,
    });

    try {
      await originationService.createPwd(user_type_id, user_id, password);
      dispatch({
        type: ACTION.RESPONSE_PWD_CREATION,
      });
    } catch (error) {
      dispatch({
        type: ACTION.ERROR_PWD_CREATION,
        error,
      });
    }
  };

export const postAddress = (userId, userType, body) => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_POST_ADDRESS,
  });

  try {
    const responseSendAddress = await originationService.sendAddress(
      userType,
      userId,
      body
    );
    if (responseSendAddress) {
      dispatch({
        type: ACTION.RESPONSE_POST_ADDRESS,
        result: responseSendAddress,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_POST_ADDRESS,
      error,
    });
  }
};

export const clearPostAddress = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_POST_ADDRESS,
  });
};

export const postBusiness = (userId, userType, body) => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_PUT_BUSINESS,
  });

  try {
    const responseBusinessData = await originationService.sendBusinessData(
      userType,
      userId,
      body
    );
    if (responseBusinessData) {
      dispatch({
        type: ACTION.RESPONSE_PUT_BUSINESS,
        result: responseBusinessData,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_PUT_BUSINESS,
      error,
    });
  }
};

export const clearPostBusiness = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_PUT_BUSINESS,
  });
};

export const postBusinessAddress =
  (userId, userType, body) => async (dispatch) => {
    dispatch({
      type: ACTION.INIT_POST_BUSINESS_ADDRESS,
    });

    try {
      const responseBusinessAddressData =
        await originationService.sendBusinessAddressData(
          userType,
          userId,
          body
        );
      if (responseBusinessAddressData) {
        dispatch({
          type: ACTION.RESPONSE_POST_BUSINESS_ADDRESS,
          result: responseBusinessAddressData,
        });
      }
    } catch (error) {
      dispatch({
        type: ACTION.ERROR_POST_BUSINESS_ADDRESS,
        error,
      });
    }
  };

export const clearPostBusinessAddress = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAN_POST_BUSINESS_ADDRESS,
  });
};

export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_ERRORS,
  });
};

export const sendAdditionalData =
  (user_type_id, user_id, body) => async (dispatch) => {
    dispatch({
      type: ACTION.INIT_PUT_ADDITIONAL_DATA,
    });

    try {
      const userData = await originationService.sendAdditionalData(
        user_type_id,
        user_id,
        body
      );
      if (userData) {
        dispatch({
          type: ACTION.RESPONSE_PUT_ADDITIONAL_DATA,
          result: userData,
        });
      }
    } catch (error) {
      dispatch({
        type: ACTION.ERROR_PUT_ADDITIONAL_DATA,
        error,
      });
    }
  };

export const clearAdditionalData = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_PUT_ADDITIONAL_DATA,
  });
};

export const sendClientNewPhoneNumber =
  (user_type_id, user_id, body) => async (dispach) => {
    dispach({
      type: ACTION.INIT_CLIENT_NEW_PHONE_NUMBER,
    });

    try {
      const newPhoneData = await originationService.clientNewPhoneNumber(
        user_type_id,
        user_id,
        body
      );
      if (newPhoneData) {
        dispach({
          type: ACTION.RESPONSE_CLIENT_NEW_PHONE_NUMBER,
          result: newPhoneData,
        });
      }
    } catch (error) {
      dispach({
        type: ACTION.ERROR_CLIENT_NEW_PHONE_NUMBER,
        error,
      });
    }
  };

export const clearClientNewPhoneNumber = () => async (dispach) => {
  dispach({
    type: ACTION.CLEAR_CLIENT_NEW_PHONE_NUMBER,
  });
};

export const sendNipValidatePhone = (body) => async (dispach) => {
  dispach({
    type: ACTION.INIT_SEND_NIP_PHONE,
  });

  try {
    const responseSendNip = await originationService.sendNipValidatePhone(body);
    if (responseSendNip) {
      dispach({
        type: ACTION.RESPONSE_SEND_NIP_PHONE,
        result: responseSendNip,
      });
    }
  } catch (error) {
    dispach({
      type: ACTION.ERROR_SEND_NIP_PHONE,
      error,
    });
  }
};

export const clearSendNipValidatePhone = () => async (dispach) => {
  dispach({
    type: ACTION.CLEAR_SEND_NIP_PHONE,
  });
};

export const validatePhoneNip = (body) => async (dispach) => {
  dispach({
    type: ACTION.INIT_VALIDATE_NIP_PHONE,
  });

  try {
    const responseValidateNip = await originationService.validatePhoneNip(body);
    if (responseValidateNip) {
      dispach({
        type: ACTION.RESPONSE_VALIDATE_NIP_PHONE,
        result: responseValidateNip,
      });
    }
  } catch (error) {
    dispach({
      type: ACTION.ERROR_VALIDATE_NIP_PHONE,
      error,
    });
  }
};

export const clearValidatePhoneNip = () => async (dispach) => {
  dispach({
    type: ACTION.CLEAR_VALIDATE_NIP_PHONE,
  });
};

export const postReverseOtp = (phoneNumber, anonymousId) => async (dispach) => {
  dispach({
    type: ACTION.INIT_POST_REVERSE_OTP,
  });

  try {
    let ipAddress = "127.0.0.1";
    const ipResponse = await fetch("https://api.ipify.org?format=json");
    const ipData = await ipResponse.json();
    ipAddress = ipData.ip;
    const responseReverseOtp = await originationService.postReverseOtp(
      phoneNumber,
      anonymousId,
      ipAddress
    );
    if (responseReverseOtp) {
      dispach({
        type: ACTION.RESPONSE_POST_REVERSE_OTP,
        result: responseReverseOtp.data,
      });
    }
  } catch (error) {
    dispach({
      type: ACTION.ERROR_POST_REVERSE_OTP,
      error,
    });
  }
};

export const validateReverseOtp = (sessionId) => async (dispach) => {
  dispach({
    type: ACTION.INIT_VALIDATE_REVERSE_OTP,
  });

  try {
    const responseReverseOtp = await originationService.validateReverseOtp(
      sessionId
    );
    if (responseReverseOtp) {
      dispach({
        type: ACTION.RESPONSE_VALIDATE_REVERSE_OTP,
        result: responseReverseOtp,
      });
    }
  } catch (error) {
    dispach({
      type: ACTION.ERROR_VALIDATE_REVERSE_OTP,
      error,
    });
  }
};
